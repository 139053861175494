import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import jsStyles from './jsStyles';
import Olkweb4Hero from '../../utils/pictures/olkweb4hero.png';
import Olkweb4HeroMobile from '../../utils/pictures/Olkweb4HeroMobile.png';
import { InfoCard, PersonCard } from '../../components';
import Olkweb4Footer from './Footer/Olkweb4Footer';
import Olkweb4Header from './Header/Olkweb4Header';
import MoreAboutOlkweb4Section from '../../components/MoreAboutOlkweb4Section/MoreAboutOlkweb4Section';
import Olkweb4HeaderAndFrontImage from '../../components/Olkweb4HeaderAndFrontImage/Olkweb4HeaderAndFrontImage';
import useWindowDimensions from '../../services/useWindowDimensions';
import {
  BrukervennlighetIcon,
  YtelsesIcon,
  MobiltilpassetIcon,
  UniversellUtformingIcon,
  GdprIcon,
  SikkerhetsIcon,
  KalenderIntegrasjonsIcon,
  KommunikasjonsIcon,
  IntegrasjonsIcon,
  ArrowDownIcon,
  InterfaceTestingIcon,
} from '../../utils/icons';
import {
  Petter,
  GeirArne,
  JoVetle,
  Lasse,
  Tomasz,
  Kristine,
  Line,
} from '../../utils/pictures';

const Olkweb4Page = (): JSX.Element => {
  const classes = jsStyles();

  const isMobile = useWindowDimensions().width <= 550;

  const renderImage = () => {
    if (isMobile) {
      return Olkweb4HeroMobile;
    } else {
      return Olkweb4Hero;
    }
  };
  return (
    <div className={styles.page}>
      <Olkweb4Header />
      <div className={styles.container}>
        <Olkweb4HeaderAndFrontImage
          headline='Helt nye Olkweb 4.0 ^'
          imgsrc={renderImage()}
          altText='Olkweb Versjon 4.0 på mobil og desktop. Versjon 4.0 er planlagt å komme i Q4 2024.'
        />

        <section className={styles.informationSection}>
          <div className={styles.cardWrapper}>
            <InfoCard
              title='Brukervennlighet'
              description='Færre klikk og kjapp respons på handlinger'
            >
              <BrukervennlighetIcon />
            </InfoCard>
            <InfoCard
              title='Bedre ytelse med ny teknologi'
              description='Raskere i alle ledd'
            >
              <YtelsesIcon />
            </InfoCard>
            <InfoCard
              title='Mobiltilpasset for alle brukergrupper'
              description='Alle brukerflater tilpasset store og små skjermer'
            >
              <MobiltilpassetIcon />
            </InfoCard>
            <InfoCard
              title='Universell utforming'
              description='Oppfyller universell design og WCAG for optimal tilgjengelighet'
            >
              <UniversellUtformingIcon />
            </InfoCard>
            <InfoCard
              title='GDPR'
              titleLine2='(Lagring i Norge)'
              description='Innebygget personvern og all data lagres i Norge'
            >
              <GdprIcon />
            </InfoCard>
            <InfoCard
              title='Sikkerhet'
              description='Sikkerhetstiltak på høyt nivå i jobbprosesser, applikasjon og nettverk'
            >
              <SikkerhetsIcon />
            </InfoCard>
            <InfoCard
              title='Kalenderintegrasjon'
              description='Integrasjon med Outlook-kalender for effektiv planlegging og tidsstyring'
            >
              <KalenderIntegrasjonsIcon />
            </InfoCard>
            <InfoCard
              title='Forbedret kommunikasjon'
              description='E-post, SMS, push-notifikasjoner: Fremmer sømløs kommunikasjon mellom systembrukere'
            >
              <KommunikasjonsIcon />
            </InfoCard>
            <InfoCard
              title='Integrasjoner mot relevante parter'
              description='Spar tid med effektive integrasjoner mot relevante parter'
            >
              <IntegrasjonsIcon />
            </InfoCard>
          </div>
          <a type='button' href='/olkweb4/#betatester'>
            <Typography className={classes.button}>
              Følg med på utviklingen vår
            </Typography>
            <ArrowDownIcon className={styles.arrow} />
          </a>
        </section>
        <section className={styles.olkweb4ArticleSectionContainer}>
          <MoreAboutOlkweb4Section></MoreAboutOlkweb4Section>
        </section>
        <section className={styles.theTeam}>
          <div className={styles.text}>
            <h2 className={styles.heading}>
              Et stort team med lang erfaring i brukerfokusert utvikling
            </h2>
            <Typography className={classes.body}>
              I Arribatec har vi tilgang på et stort antall erfarne spesialister
              innen utvikling, brukertesting og sikkerhet. Møt laget som nå
              utvikler en helt ny versjon:{' '}
            </Typography>
          </div>
          <div className={styles.personCardWrapper}>
            <PersonCard
              fullName='Petter Bru'
              jobTitle='Senior prosjektleder'
              imgSrc={Petter}
            />
            <PersonCard
              fullName='Geir Arne Mo Bjørnseth'
              jobTitle='Senior utvikler
              (Security Tech Lead)'
              imgSrc={GeirArne}
            />
            <PersonCard
              fullName='Jo Vetle Albrethsen'
              jobTitle='Backend utvikler'
              imgSrc={JoVetle}
            />
            <PersonCard
              fullName='Lasse Folkestad Soltvedt'
              jobTitle='Senior utvikler'
              imgSrc={Lasse}
            />
            <PersonCard
              fullName='Tomasz Rudowski'
              jobTitle='Senior utvikler'
              imgSrc={Tomasz}
            />
            <PersonCard
              fullName='Kristine Lundquist'
              jobTitle='UX team lead'
              imgSrc={Kristine}
            />
            <PersonCard
              fullName='Line Løver Urdalen'
              jobTitle='UX designer'
              imgSrc={Line}
            />
          </div>
        </section>
        <section id='betatester' className={styles.betatesterSection}>
          <div className={styles.detailsContainer}>
            <div className={styles.textContainer}>
              <InterfaceTestingIcon className={styles.iconTop} />
              <h2 className={styles.heading}>
                Følg med på vår spennende reise og bli en betatester!
              </h2>
              <div className={styles.descriptionContainer}>
                <div className={styles.text}>
                  <Typography className={classes.important}>
                    Vi er stolte over å kunngjøre at utviklingen av den nye
                    Olkweb er godt i gang, og vi forventer å ha den klar i løpet
                    av 2024.
                  </Typography>
                  <Typography className={classes.body}>
                    Vi samarbeider allerede tett med flere brukergrupper, men vi
                    ønsker å utvide vår krets av betatestere. Hvis du er
                    interessert i å få et førstehåndsinntrykk av Olkweb og bidra
                    til å forme fremtiden for lærlingoppfølging, vil vi gjerne
                    høre fra deg. Vennligst fyll ut skjemaet under med din
                    kontaktinformasjon, så legger vi deg til i listen over
                    potensielle testere.
                  </Typography>
                </div>
                <InterfaceTestingIcon className={styles.iconSide} />
              </div>
            </div>
          </div>
          <div className={styles.betatestForm}>
            <div className={styles.textContainer}>
              <h3>Meld deg som betatester:</h3>
              <Typography className={classes.body}>
                Send en epost til
              </Typography>
              <a
                className={styles.mail}
                href='mailto:kundeservice@arribatec.com'
              >
                kundeservice@arribatec.com
              </a>
              <Typography className={classes.body}>
                med følgende informasjon:
              </Typography>
              <ul>
                <li>
                  <Typography className={classes.body}>
                    Jeg ønsker å bli betatester for Olkweb 4.0
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.body}>Ditt navn</Typography>
                </li>
                <li>
                  <Typography className={classes.body}>
                    Hvilken rolle vil du teste i Olkweb? (Lærling - Veileder -
                    Opplæringskonsulent)
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.body}>
                    Hvilket opplæringskontor tilhører du?
                  </Typography>
                </li>
              </ul>
              <div>
                <Typography className={classes.body}>
                  Ønsker du i tilegg å motta nyhetsbrev med nyheter om Olkweb
                  kan du ta med:
                </Typography>
                <ul>
                  <li>
                    <Typography className={classes.body}>
                      Jeg ønsker å motta nyhetsbrev om Olkweb
                    </Typography>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Olkweb4Footer />
    </div>
  );
};

export default Olkweb4Page;
