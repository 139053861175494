import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import jsStyles from './jsStyles';

const InfoCard = ({
  title,
  titleLine2,
  description,
  children,
}: {
  title: string;
  titleLine2?: string;
  description: string;
  children?: ReactNode;
}): JSX.Element => {
  const classes = jsStyles();
  return (
    <div className={styles.card}>
      {children && <div>{children}</div>}
      <div style={{ marginBottom: '40px' }}>
        <h2 className={classes.header}>{title}</h2>
        {titleLine2 && <h2 className={classes.header}>{titleLine2}</h2>}
        <Typography className={classes.body}>{description}</Typography>
      </div>
    </div>
  );
};

export default InfoCard;
