import { makeStyles } from '@material-ui/core/styles';

const jsStyles = makeStyles({
  title: {
    width: '415px',
    fontSize: '45px',
    fontFamily: 'Almarena-DisplayLight',
    lineHeight: 1.5,
    '@media screen and (max-width: 700px)': {
      fontSize: '30px',
      width: '276px',
    },
  },
  subheading: {
    fontSize: '30px',
    fontFamily: 'Almarena-DisplayLight',
    lineHeight: '40px',
    color: 'var(--Black, #000)',
    '@media screen and (max-width: 700px)': {
      fontSize: '22px',
    },
  },
  button: {
    fontSize: '24px',
    fontFamily: 'Inter',
    marginBottom: 0,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    '@media screen and (max-width: 700px)': {
      fontSize: '20px',
    },
  },
  important: {
    fontSize: '20px',
    fontFamily: 'Inter-medium',
    marginTop: 0,
    lineHeight: '28px',
    '@media screen and (max-width: 700px)': {
      fontSize: '18px',
      lineHeight: '25px',
    },
  },
  body: {
    fontSize: '20px',
    fontFamily: 'Inter',
    marginTop: 0,
    lineHeight: '28px',
    '@media screen and (max-width: 700px)': {
      fontSize: '18px',
      lineHeight: '25px',
    },
  },
});

export default jsStyles;
