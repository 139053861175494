import React from 'react';
import styles from '../styles.module.scss';
import { Typography } from '@material-ui/core';
import ButtonBase from '@mui/material/ButtonBase';

interface Olkweb4ArticleColumnProps {
  image: string;
  headerText: string;
  breadText: string;
  onClick: React.MouseEventHandler;
  buttonText: string;
}

const Olkweb4ArticleColumn: React.FC<Olkweb4ArticleColumnProps> = ({
  image,
  headerText,
  breadText,
  onClick,
  buttonText,
}) => {
  return (
    <div className={styles.article}>
      <img src={image} alt='' />
      <h3>{headerText}</h3>
      <Typography>{breadText}</Typography>
      <ButtonBase className={styles.button} onClick={onClick} disableRipple>
        {buttonText}
      </ButtonBase>
    </div>
  );
};

export default Olkweb4ArticleColumn;
