import React from 'react';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import SikkerhetBilde from '../../utils/pictures/SikkerhetBilde.svg';
import BrukerOpplevelseBilde from '../../utils/pictures/BrukeropplevelseBilde.svg';
import UniversellUtformingBilde from '../../utils/pictures/UniversellUtformingBilde.svg';
import Olkweb4AricleColumn from './Olkweb4ArticleColumn/Olkweb4ArticleColumn';

const MoreAboutOlkweb4Section: React.FC = () => {
  const navigate = useNavigate();

  const securityPage = () => {
    const routeURL = `/olkweb4/security`;
    navigate(routeURL);
  };
  const userExperiencePage = () => {
    const routeURL = `/olkweb4/userexperience`;
    navigate(routeURL);
  };
  const universalDesignPage = () => {
    const routeURL = `/olkweb4/universaldesign`;
    navigate(routeURL);
  };
  return (
    <div className={styles.articleSectionContainer}>
      <div className={styles.articleHeader}>
        <h2>Mer om Olkweb 4</h2>
      </div>
      <div className={styles.articlecontent}>
        <Olkweb4AricleColumn
          image={SikkerhetBilde}
          headerText='Sikkerhet er en sentral del'
          breadText='Med «Security by Design» er dine sensitive data trygge i Olkweb 4.'
          onClick={securityPage}
          buttonText='Les om hvordan vi jobber med sikkerhet'
        />

        <Olkweb4AricleColumn
          image={BrukerOpplevelseBilde}
          headerText='Med brukeren i sentrum'
          breadText='Et av målene med Olkweb 4 er å gi brukerne en enklere arbeidshverdag. '
          onClick={userExperiencePage}
          buttonText='Les om hvordan vi jobber med brukeropplevelse'
        />

        <Olkweb4AricleColumn
          image={UniversellUtformingBilde}
          headerText='Universell utforming fra start'
          breadText='Ved å lage produkter som er universelt utformet gir vi alle i samfunnet like muligheter.'
          onClick={universalDesignPage}
          buttonText='Les om hvordan vi jobber med universell utforming'
        />
      </div>
    </div>
  );
};

export default MoreAboutOlkweb4Section;
